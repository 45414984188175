import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { viewAboutUsEnabled } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import AboutUsShot from '../images/about-us-001.jpg';
import Ingedients from '../images/ingredients-001.jpeg';
import LeftPanelBackground from '../images/about-background-left.svg';

function AboutUs() {
    const enabled = useRecoilValue(viewAboutUsEnabled);

    const ItemLeft = styled(Paper)(({ theme }) => ({
        textAlign: 'left',
        fontSize: 18,
        color: theme.palette.text.secondary,
        minHeight: 860,
        backgroundImage: `url(${LeftPanelBackground})`,
        backgroundSize: "cover"
    }));

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-1" className="contentModule">
                <Grid container spacing={1}>
                    <Grid item>
                        <ItemLeft>
                            <div className="leftPanel">
                                <h3>About Us</h3>
                                <div className="containerDim">
                                    <img src={AboutUsShot} className="imageWrapLeft" alt="" />
                                    <p>
                                        We are a small, family owned, Mom and Pop (literally) bakery who just love to bake tasty bread and desserts. We bake with care and work hard to ensure the best products possible. We bake to order and use ingredients you can pronounce. Our products are always made fresh, just for you and we can do customizations on request, just ask us.
                                    </p>
                                    <p>
                                        It all started with a simple loaf of warm, fragrant and tender homemade bread, so delicious. We quickly expanded into other breads, pastries, desserts and decided to make our love of baking into a business. We make all our products with real, fresh ingredients you want in a bakery product. No artificial ingredients, no preservatives, nothing you wouldn’t use yourself. You can taste the difference between the grocery store breads and ours. More specifically, you can taste the bread, not all those other ingredients.
                                    </p>
                                    <p>
                                        When we are not out walking our dog, Riley, we are probably in the kitchen baking up the next batch of delectable goodies.
                                    </p>
                                    <p>
                                        You can order through our web site, it will send us an email to confirm your order personally. We want to make sure you get the freshest products, and that we can deliver them to you.
                                    </p>
                                </div>
                                <div className="containerHeight" />
                                <div className="containerDim">
                                    <img src={Ingedients} className="imageWrapRight" alt="" />
                                    <p>
                                        <i>Finding Our Way</i>
                                        <br />
                                        One day we really started to focus on ingredient labels on supermarket sold breads, in-store bakery breads and other baked goods and were amazed at the long list of ingredients used to make such a simple product. While these bakeries can make a nice loaf, it was all those added dough conditioners and preservatives we wanted to avoid. We use ingredients you can pronounce, and no artificial preservatives or additives. We carefully develop, test, and sample every recipe until it is perfect. We don't sell anything we don't eat ourselves. You can see, smell and taste the quality in our products.
                                    </p>
                                    <p>
                                        We opened our home-based business because we want to share our high quality, lovingly developed breads and other baked goods with everyone. When you don't have time to spend hours in the kitchen baking, we will happily bake for you. Many of our breads and products take a lot of time to produce and we don't rush flavor development.
                                    </p>
                                    <p>
                                        With love from our kitchen to yours.
                                    </p>
                                </div>
                            </div>
                        </ItemLeft>
                    </Grid>
                </Grid>
            </div>
        </Fade>      
    );
}

export default AboutUs;