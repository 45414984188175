import * as React from 'react';
import { viewAboutUsEnabled, viewProductBreads, viewProductCookies, viewProductSweetGoods, viewGetInTouchEnabled } from '../atoms/Header-Atoms'
import { useRecoilState } from 'recoil';
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import IconLogo from '../assets/logo.svg';

function Header() {
    const [enabledAbout, setEnabledAbout] = useRecoilState(viewAboutUsEnabled);
    const [enabledProductBreads, setEnabledProductBreads] = useRecoilState(viewProductBreads);
    const [enabledProductCookies, setEnabledProductCookies] = useRecoilState(viewProductCookies);
    const [enabledProductSweetGoods, setEnabledProductSweetGoods] = useRecoilState(viewProductSweetGoods);
    const [enabledTouch, setEnabledTouch] = useRecoilState(viewGetInTouchEnabled);

    const handleChange1 = () => {
        setEnabledAbout(true);
        setEnabledProductBreads(false);
        setEnabledProductCookies(false);
        setEnabledProductSweetGoods(false);
        setEnabledTouch(false);
        resetToTop();
    };

    const handleChange2 = () => {
        setEnabledAbout(false);
        setEnabledProductBreads(true);
        setEnabledProductCookies(false);
        setEnabledProductSweetGoods(false);
        setEnabledTouch(false);
        resetToTop();
    };

    const handleChange3 = () => {
        setEnabledAbout(false);
        setEnabledProductBreads(false);
        setEnabledProductCookies(true);
        setEnabledProductSweetGoods(false);
        setEnabledTouch(false);
        resetToTop();
    };

    const handleChange4 = () => {
        setEnabledAbout(false);
        setEnabledProductBreads(false);
        setEnabledProductCookies(false);
        setEnabledProductSweetGoods(true);
        setEnabledTouch(false);
        resetToTop();
    };

    const handleChange5 = () => {
        setEnabledAbout(false);
        setEnabledProductBreads(false);
        setEnabledProductCookies(false);
        setEnabledProductSweetGoods(false);
        setEnabledTouch(true);
        resetToTop();
    };

    const resetToTop = () => {
        document.getElementById("main").scroll(0,0);
    };

  return (
    <nav className="nav-elevation">
      <div className="nav-container">
        <div className="nav-left">
            <Button onClick={handleChange1}><img src={IconLogo} alt="" /></Button>
        </div>
        <div className="nav-right">
          <Stack direction="row" spacing={2}>
            <Button onClick={handleChange1} variant="outlined">About Us</Button>
            <Button onClick={handleChange2} variant="outlined">Breads</Button>
            <Button onClick={handleChange3} variant="outlined">Cookies</Button>
            <Button onClick={handleChange4} variant="outlined">Sweet Goods</Button>
            <Button onClick={handleChange5} variant="outlined">Get In Touch</Button>
          </Stack>
        </div>
      </div>
    </nav>
  );
}

  export default Header;