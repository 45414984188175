import AboutUs from "./AboutUs";
import ProductBreads from "./ProductBreads";
import ProductCookies from "./ProductCookies";
import ProductSweetGoods from "./ProductSweetGoods";
import GetInTouch from "./GetInTouch";

function MainContent() {
  return (
    <div className="main-container">
      <AboutUs />
      <ProductBreads />
      <ProductCookies />
      <ProductSweetGoods />
      <GetInTouch />
    </div>
  );
}

export default MainContent;