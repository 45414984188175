import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { viewProductCookies } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import AlmondCookie from '../images/almond-cookie.png';
import ChocolateChipCookie from '../images/chocolate-chip-cookie.png';
import BakeryStyleChocolateChipCookie from '../images/bakery-style-chocolate-chip-ccokie.png';
import OatmealRaisinCookie from '../images/oatmeal-raisin-cookie.png';
import BakeryStyleGingerMolassesCookie from '../images/bakery-style-ginger-molasses-cookie.png';
import PeanutButterCookie from '../images/peanut-butter-cookie.png';
import MaineOatmealCookie from '../images/maine-oatmeal-cookies.png';
import ChocolateSables from '../images/chocolate-sables.png';
import WheatSnacks from '../images/little-tender.png';

function ProductCookies() {
    const enabled = useRecoilValue(viewProductCookies);

    const handleClick = (e, param) => {
        var body = "";
        var subject = "";

        switch(param) {
            case 'almondCookie':
                subject = "Bleu Fox Bakery Online Order - Almond Cookie";
                body = "Thank you for your interest in the Almond Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'chocolateChipCookie':
                subject = "Bleu Fox Bakery Online Order - Homestyle Choclate Chip Cookie";
                body = "Thank you for your interest in the Homestyle Chocolate Chip Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'bakeryStyleChocolateChipCookie':
                subject = "Bleu Fox Bakery Online Order - Bakery Style Choclate Chip Cookie";
                body = "Thank you for your interest in the Homestyle Chocolate Chip Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'oatmealRaisinCookie':
                subject = "Bleu Fox Bakery Online Order - Oatmeal Raisin Cookie";
                body = "Thank you for your interest in the Oatmeal Raisin Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'bakeryStyleGingerMolassesCookie':
                subject = "Bleu Fox Bakery Online Order - Bakery Style Ginger Molasses Cookie";
                body = "Thank you for your interest in the Homestyle Chocolate Chip Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'peanutButterCookie':
                subject = "Bleu Fox Bakery Online Order - Peanut Butter Cookie";
                body = "Thank you for your interest in the Peanut Butter Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'maineOatmealCookie':
                subject = "Bleu Fox Bakery Online Order - Maine Oatmeal Crunchies";
                body = "Thank you for your interest in the Maine Oatmeal Crunchies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'chocolateSables':
                subject = "Bleu Fox Bakery Online Order - Choclate Sablés";
                body = "Thank you for your interest in the Chocolate Sablés. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'wheatSnacks':
                subject = "Bleu Fox Bakery Online Order - Wheat Snacks";
                body = "Thank you for your interest in the Wheat Snacks. Please indicate the quantity, flavor and doneness you would like to order and we'll have someone contact shortly if within business hours.";
                break;  
            default:
                subject = "Bleu Fox Bakery Online Order - Products";
                body = "Thank you for your interest in our offerings. Please indicate the item(s) and quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
        }

        var email="sales@bleufoxbakery.com";
        var mailto_link='mailto:'+email+'?subject='+subject+'&body='+body;
        window.open(mailto_link,'_self');
    };

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-3" className="contentModule">
                <Grid container spacing={1}>  
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={AlmondCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Almond Cookie
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>On the surface, this delightful little cookie may seem bland, but once you take a bite you'll be reaching for another  and another in no time. These cookies yield a wonderful almond flavor with a mild sweetness, crisp exterior and a soft center with a slight chew.</p>
                                        <p>They pair perfectly with a delicate cup of tea, rich dark coffee or even a simple glass of milk.</p>
                                        <p>Ingredients: Flour, Sugar, Almonds, Butter, Cornstarch, Brandy and Milk.</p>
                                        <p>Price: $6 USD (6 count), $9 USD (12 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'chocolateChipCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>                  
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={ChocolateChipCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Homestyle Chocolate Chip Cookie
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Taste a bit of nostalgia with these soft, sweet and delicious cookies baked fresh for you. These are so good, even the next day, they retain their soft texture (store in an air-tight container). Everything you expect from an amazing chocolate chip cookie.</p>
                                        <p>Ingredients: Flour, Butter, Brown Sugar, Sugar, Eggs, Chocolate and Dark Chocolate, Vanilla, Baking Soda, Cornstarch and Salt.</p>
                                        <p>Price: $5 USD (3 count), $8 USD (6 count), $12 USD (12 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'chocolateChipCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={BakeryStyleChocolateChipCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Bakery Style Chocolate Chip Cookie
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>This is our ideal version of a bakery style chocolate chip cookie. It has that classic toffee-like flavor, with a mix of bittersweet and semi-sweet chocolate chunks, a wonderful, chewy center, crispy edges and a larger size.</p>
                                        <p>Ingredients: Flour, Butter, Chocolate, Brown Sugar, Sugar, Egg, Vanilla, Baking Soda, Baking Powder and Salt.</p>
                                        <p>Price: $2.50 USD (per cookie)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'peanutButterCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={OatmealRaisinCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Oatmeal Raisin Cookie
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>A classic favorite. Our freshly baked Oatmeal Raisin cookies have a soft interior and crispy edges. Plump, juicy raisins and rolled oats pack a big punch in these small cookies. Rolled in powdered sugar and baked to perfection, these will have you asking for more.</p>
                                        <p>Ingredients: Raisins, Rolled Oats, Flour, Sugar, Butter, Egg, Vegetable Shortening, Vanilla, Baking Soda, Salt, Molasses, Cinnamon and Baking Powder.</p>
                                        <p>Price: $5 USD (6 count), $9 USD (12 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'peanutButterCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={BakeryStyleGingerMolassesCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Bakery Style Ginger Molasses Cookie
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Chewy and soft cookies with a crunchy sugar coating. These are sweet with a warm spice from the ginger and cloves. If you like ginger, you'll love these flavorful cookies. They will stay soft and chewy for a while, but eat them up within a few days - we don't use any preservatives.</p>
                                        <p>Ingredients: Flour, Butter, Sugar, Brown Sugar, Molasses, Egg, Baking Soda, Ginger, Cinnamon, Salt and Clove.</p>
                                        <p>Price: $2.50 USD (per cookie)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'peanutButterCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={PeanutButterCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Peanut Butter Cookies
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Not your average Peanut Butter cookie. We use natural peanut butter that gives the cookies a sweet flavor without being overpowering, allowing the peanut butter taste to shine. The cookies have a pillowy soft texture with a slight chew. You'll want to order these by the dozen, it's hard to eat just one.</p>
                                        <p>Ingredients: Flour, Natural Peanut Butter, Brown Sugar, Sugar, Butter, Cornstarch, Baking Soda, Baking Powder and Salt.</p>
                                        <p>Price: $5 USD (6 count), $8 USD (12 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'peanutButterCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={MaineOatmealCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Maine Oatmeal Crunchies
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>A family recipe handed down from “Great Aunt Rete” who baked these on special occasions. These small but amazing cookies are packed with a wonderful toffee like flavor and baked to crispy perfection. Pecans round things out nicely but we can make them without to order. If you don’t care for oatmeal cookies, these will change your mind. If you love oatmeal cookies, these are the cookies for you.</p>
                                        <p>Ingredients: Oats, Brown Sugar, Flour, Sugar, Vegetable Oil, Pecans ( optional ), Egg, Baking Soda, Salt and Vanilla.</p>
                                        <p>Price: $6 USD (10 count), $10 USD (20 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'maineOatmealCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={ChocolateSables}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Chocolate Sablés
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>A small cookie with a crisped exterior, slight chew and a robust chocolate flavor, these Chocolate Sablés are transcendent. Contains a dark and semi-sweet chocolate blend that pair perfectly with a hint of kosher salt that concentrate the amazing flavors of this small but incredibly flavorful cookie.</p>
                                        <p>Ingredients: Brown Sugar, Flour, Butter, Sugar, Dark and Semisweet Chocolate, Cocoa Powder, Baking Soda, Vanilla and Salt.</p>
                                        <p>Price: $7 USD (12 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'chocolateSables');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={WheatSnacks}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Wheat Snacks
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>A bone shaped biscuit like snack you can share with your best friend. Human quality snacks with just a hint of sweetness that everyone will love. Portable and small enough to fit in your pocket. A great on-the-go travel treat. Available flavors: <i>Peanut Butter, Pumpkin or Sweet Potato</i></p> 
                                        <p>Ingredients: Whole Wheat Flour, Peanuts / Sweet Potato / Pumpkin Puree ( depending on the order ), Water, Egg, Honey and Olive Oil.</p>                                       
                                        <p>Price: (25 count) $5 USD | (50 count) $10 USD</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'wheatSnacks');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Fade>      
    );
}

export default ProductCookies;