import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { viewProductSweetGoods } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import BananaBread from '../images/banana-bread.png';
import FruitBuckle from '../images/fruit-buckle.png';
import ChocolateBiscotti from '../images/chocolate-biscotti.png';
import MonsterBrownies from '../images/monster-brownies.png';

function ProductSweetGoods() {
    const enabled = useRecoilValue(viewProductSweetGoods);

    const handleClick = (e, param) => {
        var body = "";
        var subject = "";

        switch(param) {
            case 'fruitBuckle':
                subject = "Bleu Fox Bakery Online Order - Fruit Buckle";
                body = "Thank you for your interest in the Fruit Buckle. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'chocolateBiscotti':
                subject = "Bleu Fox Bakery Online Order - Choclate Biscotti";
                body = "Thank you for your interest in the Chocolate Biscotti. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'monsterBrownies':
                subject = "Bleu Fox Bakery Online Order - Brownie Minis";
                body = "Thank you for your interest in the Brownie Minis. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            default:
                subject = "Bleu Fox Bakery Online Order - Products";
                body = "Thank you for your interest in our offerings. Please indicate the item(s) and quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
        }

        var email="sales@bleufoxbakery.com";
        var mailto_link='mailto:'+email+'?subject='+subject+'&body='+body;
        window.open(mailto_link,'_self');
    };

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-3" className="contentModule">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={BananaBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Banana Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Beautifully ripened bananas give this wonderful banana bread the perfect amount of sweetness you want in a banana bread. Soft and delicious, with a moist interior and a rich banana flavor. These mini loaves will be a hit at your breakfast or brunch table.</p>
                                        <p>Ingredients: Flour, Brown Sugar, Butter, Banana, Egg, Baking Soda and Salt.</p>
                                        <p>Price: $4 USD (each)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'monsterBrownies');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={FruitBuckle}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Fruit Buckle
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>The Fruit Buckle has an amazing combination of flavors and textures. With a rustic appearance in a tulip cup, you'll be greeted with a soft and moist crumb, mellow sweetness, and seasonal fruit. Lastly, the Fruit Buckles are topped with a delightful streusel that ties everything together.</p>
                                        <p>Ingredients: Flour, Milk, Fruit (specific to order), Sugar, Butter, Brown Sugar, Egg, Cornstarch, Baking Powder, Vanilla, Salt and Lemon Juice.</p>
                                        <p>Price: $3.50 USD (each)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'monsterBrownies');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={MonsterBrownies}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Brownie Minis
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>These are everything a brownie should be; a fudgy center, a crackly top, and chewy edges with every bite. These brownies are rich and decadent, and will satisfy your chocolate craving. Made with high quality dark chocolate. Big flavors come in small packages.</p>
                                        <p>Ingredients: Sugar, Flour, Dark Chocolate, Sunflower/Olive Oil Blend, Eggs, Cocoa Powder, Spring Water, Salt and Vanilla.</p>
                                        <p>Price: $5 USD (5 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'monsterBrownies');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={ChocolateBiscotti}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Dark Chocolate Biscotti
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>A delicious dark chocolate biscotti made to order. We use dutch-processed cocoa powder to give these biscotti their deep chocolate flavor. Made with dark chocolate chunks, these are usually double baked to give them their crunchy snap, or we can make them soft, like a lightly crunchy yet chewy cookie. Either way, a dunkable, decadent treat.</p>
                                        <p>Ingredients: Flour, Sugar, Eggs, Dark Chocolate, Butter, Cocoa Powder, Baking Soda, Nuts and Salt.</p>
                                        <p>Price: $6 USD (6 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'chocolateBiscotti');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Fade>      
    );
}

export default ProductSweetGoods;