import { atom } from "recoil";

export const viewAboutUsEnabled = atom({
    key: 'viewAboutUsEnabled',
    default: true
});

export const viewProductBreads = atom({
    key: 'viewProductBreads',
    default: false
});

export const viewProductCookies = atom({
    key: 'viewProductCookies',
    default: false
});

export const viewProductSweetGoods = atom({
    key: 'viewProductSweetGoods',
    default: false
});

export const viewGetInTouchEnabled = atom({
    key: 'viewGetInTouchEnabled',
    default: false
});